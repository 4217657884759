<template>
  <div :style="loading ? 'opacity: 0' : ''" style="background-color: white">

    <div
      v-if="false"
      style="width: 100%; height: 30px; background-color: #FFA201"
      class="d-flex white--text align-center justify-center text-uppercase shadow-sm">
      <span class="ml-2 body-2 font-weight-bold">
        <span class="mr-1">📦</span> ENVIAMENT GRATUÏT AMB LA COMPRA MÍNIMA DE 5 DOSSIERS</span>
    </div>


    <div class="mx-auto pa-6" style="height: 100%; min-height: 100%; max-width: 1500px; width: 100%">

      <!-------- BREADCRUMB ----------------->
      <v-breadcrumbs :items="path"
                     style="z-index: 10; position: absolute; left: 10px; top: 20px"
                     class="pa-0 ml-4"
      >
      </v-breadcrumbs>


      <v-row style="height: 100%; position: relative" class="fill-height d-flex" no-gutters>
        <v-col
          cols="12" md="8"
          style="height: 100%; flex: 1; min-height: 400px;"
          class="d-flex flex-column fill-height align-self-center"
        >
          <!----------------- CAROUSEL ----------------->
          <div style="position:relative; flex: 1 !important; min-height: 400px;">

            <!-------- CAROUSEL ARROWS ----------------->
            <v-card
              @click="step <= 0 ? step = 5 : step--, incrementStep=false"
              style="position: absolute; left: 0; top: calc(50% - 20px); z-index: 50"
              class="rounded-circle pa-2 no-background-hover" flat
              color="grey lighten-2"
            >
              <v-icon>{{ mdiChevronLeft() }}</v-icon>
            </v-card>
            <v-card
              @click="step >= 6 ? step = 0 : step++, incrementStep=true"
              style="position: absolute; right: 0; top: calc(50% - 20px); z-index: 50"
              class="rounded-circle pa-2 no-background-hover" flat
              color="grey lighten-2"
            >
              <v-icon>{{ mdiChevronRight() }}</v-icon>
            </v-card>


            <!-------- DOSSIER PRINCIPAL IMAGE ----------------->

            <div
              v-if="step === 0"
              class="d-flex flex-column  ma-auto" style="max-width: 600px; width: 100%">
              <v-img
                v-if="book"
                style="width: 100%; height: 100%; max-width: 100%"
                class="mb-6 rounded-lg" contain
                :src="book.image.replace('_sm.webp', '.webp')"></v-img>
            </div>
            <div
              v-if="step === 1"
              class="d-flex flex-column  ma-auto" style="max-width: 600px; width: 100%">
              <v-img
                v-if="book"
                style="width: 100%; height: 100%; max-width: 100%"
                class="mb-6 rounded-lg" contain
                :src="book.imageHover"></v-img>
            </div>


            <!-------- DOSSIER PREVIEW ----------------->
            <div
              v-if="book"
              v-for="previa in 4"
              v-show="(previa+1) === step"
              style="height: 100%; min-height: 100%; max-width: 100%"
              class="justify-center">
              <transition :name="incrementStep ?  'scroll-x-reverse-transition' : 'scroll-x-transition'">

                <v-card color="transparent"
                        v-show="(previa+1) === step"
                        flat
                >
                  <div
                    :id='`pdf-viewer${previa}`'
                    style="position: relative; max-height: 630px; min-height: 400px; max-width: fit-content; background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(238,238,238) 90%);"
                    class="ma-auto pdf-viewer align-center justify-center lighten-3 px-8 py-6 rounded-lg d-flex flex-column">

                    <div
                      v-if="previa < 3"
                      style="height: 100%; width: 100%; max-width: 100%; background-color: white"
                      class="py-1 d-flex my-auto">

                      <img
                        v-if="previa === 1"
                        style="max-width: 420px !important; width: 100%; flex: 1; z-index: 2; image-rendering: crisp-edges !important; object-fit: contain"
                        :src="`https://examenselectivitat.cat:3000/api/images/books_preview/preview_${$route.params.book_id}_${(previa)}.png`"
                        class="my-auto shadow-small pa-1 rounded-l-0"/>
                      <v-img
                        v-else
                        width="100%"
                        :height="$vuetify.breakpoint.xs ? 300 : 750"
                        position="top"
                        :src="`https://examenselectivitat.cat:3000/api/images/books_preview/preview_${$route.params.book_id}_${(previa)}.png`"
                        class="my-auto shadow-small pa-1 rounded-l-0"
                        style="margin-top: -40px !important;"
                      />
                    </div>

                    <div
                      v-else
                      style="height: 100%; width: 100%; max-width: 100%; background-color: white"
                      class="py-1 d-flex my-auto">
                      <img
                        :class="(previa === 1 || book.apunts) ? '' : 'grey-scale'"
                        style="flex: 1; image-rendering: crisp-edges !important; object-fit: contain"
                        :style="$vuetify.breakpoint.xs ? 'max-width: 180px !important;' : 'max-width: 320px !important;'"
                        :src="`https://examenselectivitat.cat:3000/api/images/books_preview/preview_${$route.params.book_id}_${((previa-1)*2)+1}.png`"
                        class="my-auto shadow-small rounded-r-0 pa-1"/>

                      <img
                        style="flex: 1; z-index: 2; image-rendering: crisp-edges !important; object-fit: contain"
                        :style="$vuetify.breakpoint.xs ? 'max-width: 180px !important;' : 'max-width: 320px !important;'"
                        :src="`https://examenselectivitat.cat:3000/api/images/books_preview/preview_${$route.params.book_id}_${((previa-1)*2)+2}.png`"
                        class="my-auto grey-scale shadow-small pa-1 rounded-l-0"/>
                    </div>

                    <div
                      v-if="$vuetify.breakpoint.smAndUp && previa > 2"
                      style="position: absolute; width: 100%; height: 100%; opacity: 0.85; left: 0; top: 0; z-index: 30"
                      class="align-center justify-center d-flex text-center my-auto">
                      <v-card max-width="300" style="border-top: 6px solid #256EFF; z-index: 30"
                              class="mx-auto shadow-small d-flex flex-column text-center justify-center px-6 py-4 rounded-lg">
                        <span class="font-rubik">Vista prèvia del dossier</span>
                        <span class="font-rubik mt-3" style="font-size: 11px">Pàgina {{ ((previa - 1) * 2) + 1 }} i {{
                            ((previa - 1) *
                              2) + 2
                          }}
                            de {{
                            book.pages
                          }}</span>
                      </v-card>
                    </div>

                  </div>
                </v-card>
              </transition>
            </div>

            <v-skeleton-loader
              v-else
              type="image" height="370" class="pb-0 ma-0 rounded-b-0"></v-skeleton-loader>


            <!-------- DOSSIER CAROUSEL INFO ----------------->
            <div v-if="step === 6">
              <v-card max-width="600" flat
                      class="mx-auto shadow-small d-flex flex-column pt-4 pb-6 px-6"
                      style="border-top: 8px solid #256EFF; border-radius: 12px;"
              >
                <span class="tit mb-3" style="font-size: 24px">Això i molt més..</span>
                <v-divider class="mb-1"></v-divider>
                <span
                  class="body-1 mt-3 text--darken-2">
                    Estudia amb els darrers problemes de la selectivitat impresos i ordenats per temes.
                  </span>

                <div
                  v-if="book"
                  class="d-flex body-1 text--darken-2 mb-3 mt-3">
                  <v-icon color="primary">{{ mdiCheck }}</v-icon>
                  <span class="ml-2">Més de {{
                      (parseInt((book.pages - 5) / 20) * 10)
                    }} problemes amb solucions</span>
                </div>
                <div class="d-flex body-1 text--darken-2 mb-3">
                  <v-icon color="primary">{{ mdiCheck }}</v-icon>
                  <span class="ml-2">Problemes classificats per temes</span>
                </div>
                <div class="d-flex body-1 text--darken-2 mb-3">
                  <v-icon color="primary">{{ mdiCheck }}</v-icon>
                  <span class="ml-2">A l'esquerra l'enunciat i a la dreta la solució</span>
                </div>

                <span class="font-weight-medium title mb-2 mt-6">Característiques</span>
                <v-divider></v-divider>
                <div class="d-flex body-1 text--darken-2 mt-3">
                  <v-icon color="grey darken-3">{{ mdiBookOpenPageVariant }}</v-icon>
                  <span class="ml-2">{{ book.pages }} pàgines</span>
                </div>
                <div class="d-flex body-1 text--darken-2 mt-3 text-start justify-start">
                  <v-icon color="grey darken-3">{{ mdiNotebookOutline }}</v-icon>
                  <span class="ml-2">Enquadernat</span>
                </div>
                <div class="d-flex body-1 text--darken-2 mt-3 text-start justify-start">
                  <v-icon color="grey darken-3">{{ mdiTruckFastOutline }}</v-icon>
                  <span class="ml-2">Enviament a domicili (1-3 dies laborals)</span>
                </div>

              </v-card>
            </div>

          </div>

          <!-------- CAROUSEL TIMELINE ----------------->
          <div style="position: absolute; bottom: -20px"
               :class="$vuetify.breakpoint.mdAndUp ? 'col-8' : ''"
          >
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              style="width: 100%; height: 50px">
              <div style="max-width: fit-content" class="mx-auto d-flex">
                <v-card v-for="dash in 7"
                        :color="dash-1 === step ? 'primary' : 'grey lighten-1'"
                        width="20" height="4" flat
                        class="mx-1"
                >
                </v-card>
              </div>
            </div>
          </div>
        </v-col>


        <!-------- RIGHT COLUMN, INFO ----------------->
        <v-col
          cols="12" md="4" class="pt-0"
          style="background-color: white"
        >
          <v-card tile color="white"
                  v-if="book"
                  style="transition: padding-top 0.1s"
                  flat class="py-0 d-flex flex-column" height="100%"
                  :class="$vuetify.breakpoint.smAndDown ? 'pa-2 mt-6' : 'pa-8'"
          >

            <span class="font-rubik font-weight-medium" style="font-size: 23px !important;">
              Dossier {{ book.apunts ? 'd\'apunts' : 'd\'exàmens' }} {{ deApostrof(selectedSubjectName) }}
            </span>

            <div class="d-flex align-start justify-start mt-3">
              <div class="d-flex flex-column">
                <div class="d-flex align-center">
                <span class="font-weight-regular font-rubik"
                      :style="book && book.oldPrice ? 'color: red; font-size: 18px !important' : ''"
                      style="font-size: 16px !important;">{{ book.price }}€</span>
                  <span
                    v-if="book && book.oldPrice"
                    class="font-weight-regular font-rubik ml-3"
                    style="font-size: 15px !important; text-decoration: line-through"
                  >
                {{ book.oldPrice }}€
              </span>
                </div>

                <!--            <span class="font-rubik mt-2" style="font-size: 20px !important; font-weight: 400 !important;">{{book.price}}€</span>-->
                <!--            <span class="font-rubik mt-2" style="font-size: 20px !important; font-weight: 400 !important;">10.95€</span>-->

                <v-rating
                  :value="4.5"
                  color="#FFD600"
                  background-color="grey lighten-1"
                  class="book-rating my-2"
                  style="margin-left: -2px"
                  half-increments
                  readonly
                  hover
                ></v-rating>
              </div>
              <v-spacer></v-spacer>

              <!--- QUANTITY SELECTOR --->
              <div class="d-flex">
                <v-card class="d-flex align-center py-1 px-3" outlined style="width: fit-content">
                  <v-btn
                    :disabled="getCookieBook() <= 0"
                    @click="decreaseQuantity(book.book_id)"
                    icon style="max-width: fit-content !important; width: fit-content !important;"
                    class="pl-0"
                  >
                    <v-icon size="18"

                            icon class="font-weight-medium mr-2" color="black">
                      {{ mdiMinus() }}
                    </v-icon>
                  </v-btn>
                  <div>
              <span
                :key="refreshQuantity + '_quantity'"
                class="font-rubik mx-2 font-weight-medium"
                style="font-size: 16px"
              >{{ getCookieBook() }}</span>
                  </div>
                  <v-icon size="18"
                          @click="increaseQuantity(book.book_id)"
                          icon class="font-weight-medium ml-2" color="black">
                    {{ mdiPlus() }}
                  </v-icon>
                </v-card>
              </div>
            </div>

            <arrivada-enviament class="mt-2"></arrivada-enviament>


            <v-hover v-slot:default="{hover}">
              <v-btn
                @click="addToCart(null)"
                height="42" style="max-height: 42px !important;"
                :class="hover ? 'shadow-small' : 'shadow-sm'"
                block depressed :color="showAfterBuyDialog ? 'grey lighten-3' : 'primary'"
                class="text-none font-weight-medium body-2 my-5 rounded-lg">
                <span v-if="!showAfterBuyDialog" style="font-size: 16px">
                  Afegir a la cistella
                </span>
                <v-icon v-else color="grey">{{ mdiCheck }}</v-icon>
              </v-btn>
            </v-hover>

            <v-img
              class="mx-auto"
              max-width="236"
              src="https://examenselectivitat.cat:3000/api/images/other/secure-payment.png"></v-img>


            <br>

            <div class="mb-5 mt-3">
              <span
                v-if="!book.apunts"
                class="font-rubik mt-1 text--darken-2">
                Estudia amb els darrers problemes de la selectivitat impresos i ordenats per temes.
              </span>
              <span
                v-else
                class="font-rubik mt-1 text--darken-2">
                Estudia amb els apunts impresos d'una estudiant que va treure un 13.198 a la selectivitat de l'any passat.
              </span>
            </div>

            <div v-if="!book.apunts">
              <!--              <div class="d-flex font-rubik text&#45;&#45;darken-2 mb-3">-->
              <!--                <v-icon color="primary">{{ mdiCheck }}</v-icon>-->
              <!--                <span class="ml-2">Més de {{ (parseInt((book.pages - 5) / 20) * 10) }} problemes amb solucions</span>-->
              <!--              </div>-->
              <div class="d-flex font-rubik text--darken-2 mb-3">
                <v-icon color="primary">{{ mdiCheck }}</v-icon>
                <span class="ml-2">Problemes classificats per temes</span>
              </div>
              <div class="d-flex font-rubik text--darken-2 mb-3">
                <v-icon color="primary">{{ mdiCheck }}</v-icon>
                <span class="ml-2">A l'esquerra l'enunciat i a la dreta la solució</span>
              </div>

              <!--              <div class="d-flex font-rubik text&#45;&#45;darken-2 mb-3">-->
              <!--                <v-icon color="primary">{{ mdiCheck }}</v-icon>-->
              <!--                <span class="ml-2">Inclosos darrers exàmens del 2023</span>-->
              <!--              </div>-->
            </div>

            <div v-else class="mb-6">
              <div class="d-flex font-rubik text--darken-2 mb-3">
                <v-icon color="primary">{{ mdiCheck }}</v-icon>
                <span class="ml-2">Tota la teoria que necessites</span>
              </div>
              <div class="d-flex font-rubik text--darken-2 mb-3">
                <v-icon color="primary">{{ mdiCheck }}</v-icon>
                <span class="ml-2">Classificats per temes</span>
              </div>
              <div class="d-flex font-rubik text--darken-2 mb-3">
                <v-icon color="primary">{{ mdiCheck }}</v-icon>
                <span class="ml-2">Amb esquemes</span>
              </div>

            </div>


            <span class="font-weight-medium font-rubik mb-1 mt-4"
                  style="font-size: 19px"
            >
              Característiques
            </span>
            <v-divider></v-divider>
            <div class="d-flex font-rubik text--darken-2 mt-3 text-start justify-start">
              <v-icon color="grey darken-3">{{ mdiTruckFastOutline }}</v-icon>
              <span class="ml-2">Enviament a domicili (1-3 dies laborals)</span>
            </div>
            <div class="d-flex font-rubik text--darken-2 mt-3">
              <v-icon color="grey darken-3">{{ mdiBookOpenPageVariant }}</v-icon>
              <span class="ml-2">{{ book.pages }} pàgines</span>
            </div>
            <div class="d-flex font-rubik text--darken-2 mt-3 text-start justify-start">
              <v-icon color="grey darken-3">{{ mdiNotebookOutline }}</v-icon>
              <span class="ml-2">Enquadernat</span>
            </div>
            <div class="d-flex font-rubik text--darken-2 mt-3 text-start justify-start">
              <v-icon color="grey darken-3">{{ mdiCircleHalfFull }}</v-icon>
              <span class="ml-2">Impresió en {{ book.apunts ? 'color' : 'blanc i negre (Portada en color)' }}</span>
            </div>


          </v-card>
        </v-col>
      </v-row>

      <div class="px-4 mt-4">
      <span class="font-weight-medium font-rubik mb-1 mt-5"
            style="font-size: 19px"
      >
              Continguts
            </span>
        <v-divider></v-divider>
        <div class="mt-4"></div>
        <span>
            Exàmens que conté el dossier de la selectivitat {{ deApostrof(selectedSubjectName) }}<br>
      </span>

        <div
          v-if="book.content"
        >
          <v-row no-gutters>
            <v-col
              v-for="content in book.content"
              cols="12"
              sm="12"
              md="6"
            >
              <ul
                v-for="row in content"

              >
                <li>
              <span class="font-rubik">
              De l'any {{ row.year }}
                </span>
                </li>
                <ul>
                  <li v-for="exam in row.exams">
                    <span class="font-rubik">{{ exam }}</span>
                  </li>

                </ul>
              </ul>
            </v-col>
          </v-row>
        </div>


      </div>

      <div class="mt-6 px-4">
      <span class="font-weight-medium font-rubik mb-1 mt-5"
            style="font-size: 19px"
      >
              Dossiers relacionats
            </span>
        <v-divider></v-divider>
      </div>
      <v-sheet
        v-if="false"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-12 mt-6' : 'mt-12 pt-12'">

        <items-row
          v-if="suggestedBooks"
          title="Altres assignatures que et poden interessar"
          :items="suggestedBooks.slice(0, columns)"
        ></items-row>

        <reviews :list="true"></reviews>


      </v-sheet>


      <checkout-suggested
        class="mt-4"
        :items="cartItems"
        :allBooks="shopList"
        :book-page="true"
      ></checkout-suggested>


    </div>


    <a id="link"></a>


    <dossiers-images
      v-if="allBooks && allBooks.length"
    ></dossiers-images>


    <v-card
      v-if="allBooks && allBooks.length"
      class="py-12" flat color="white">
      <div style="max-width: 1500px" class="mx-auto px-4 text-center">
        <reviews></reviews>
      </div>
    </v-card>


    <checkout-suggested
      class="mt-10 mx-auto pb-10"
      :items="cartItems"
      :allBooks="shopList"
      :book-page="true"
      style="max-width: 1500px"
    ></checkout-suggested>

    <alerta-literatura
      :literaturaAlert.sync="literaturaAlert"
      :literaturaAlertMsg="literaturaAlertMsg"
      :books="allBooks"
      @addToCart="addToCart"
    ></alerta-literatura>

  </div>
</template>

<script>

import {mdiCheck, mdiMinus, mdiMinusThick, mdiPlus} from '/src/assets/mdi.json'
import {mdiNotebookOutline} from '/src/assets/mdi.json'
import {mdiTruckFastOutline} from '/src/assets/mdi.json'
import {mdiBookOpenPageVariant} from '/src/assets/mdi.json'
import {mdiCircleHalfFull} from '/src/assets/mdi.json'
import {mdiClose} from '/src/assets/mdi.json'


import ItemsRow from "./components/ProductsRow.vue";
import Reviews from "./components/Reviews";
import {mdiChevronLeft, mdiChevronRight} from "/src/assets/mdi.json";
import DialogAfterBuy from "./components/DialogAfterBuy.vue";
import Shop from "./Shop";
import CheckoutSuggested from "./cart/components/CartSuggested.vue";
import DossiersImages from "./components/DossiersImages.vue";
import AlertaLiteratura from "./components/AlertaLiteratura.vue";
import ArrivadaEnviament from "./components/ArrivadaEnviament.vue";


export default {
  name: "ShopPage",
  components: {
    ArrivadaEnviament,
    AlertaLiteratura,
    DossiersImages,
    CheckoutSuggested,
    DialogAfterBuy,
    Reviews,
    ItemsRow
  },
  data() {
    return {
      mdiCheck: mdiCheck,
      mdiNotebookOutline: mdiNotebookOutline,
      mdiTruckFastOutline: mdiTruckFastOutline,
      mdiBookOpenPageVariant: mdiBookOpenPageVariant,
      mdiCircleHalfFull: mdiCircleHalfFull,
      mdiClose: mdiClose,
      path: [
        {
          text: 'Dossiers',
          disabled: false,
          exact: true,
          to: '/dossiers-selectivitat',
        },
        {
          text: "",
          disabled: true,
        }
      ],
      selectedSubject: null,
      selectedSubjectName: null, // Llengua catalana
      showPdf: false,
      canvasWidth: 400,
      book: null,
      suggestedBooks: null,
      loading: false,
      step: 0,
      incrementStep: false,
      subjectsBranches: null,
      url: process.env.VUE_APP_AXIOS_URI,
      showImage: {},
      update: 0,
      nPages: 0,
      refreshQuantity: 0,
      showAfterBuyDialog: false,
      allBooks: [],
      shopList: [],
      literaturaAlert: false,
      literaturaAlertMsg: ""
    }
  },
  computed: {
    cartItems() {
      this.list = this.$cookies.get("cart")

      let books = []
      this.shopList.forEach(d => books = books.concat([...d.subjects, ...d.apunts]));
      //
      let items = [];
      books.forEach(b => {
        if (this.list[b.book_id]) {
          b.quantity = this.list[b.book_id];
          b.offers = [];
          b.offerPrices = {
            offer: 0,
            normal: b.quantity
          };

          for (let i = 0; i < b.quantity; i++) {
            b.offers.push(false);
          }
          items.push(b)
        }
      })

      return items
    },
    list() {
      return this.$cookies.get("cart");
    },
    columns() {
      const dim = this.$vuetify.breakpoint.name;
      if (dim === 'xs')
        return 3;
      else if (dim === 'sm')
        return 4;
      else if (dim === 'md')
        return 6;
      else if (dim === 'lg')
        return 8;
      else (dim === 'xl')
      return 8;
    },
  },
  methods: {
    getCookieBook() {
      let list = this.$cookies.get('cart');
      const book_id = this.$route.params.book_id
      return list[book_id] ? list[book_id] : 0
    },
    decreaseQuantity() {
      this.$root.cart--;
      let list = this.$cookies.get('cart');
      const book_id = this.$route.params.book_id
      list[book_id]--
      this.$cookies.set('cart', list)
      this.refreshQuantity++;
      this.showAfterBuyDialog = false;

    },
    increaseQuantity() {
      this.$root.cart++;
      let list = this.$cookies.get('cart');
      const book_id = this.$route.params.book_id
      if (!list[book_id]) list[book_id] = 0;
      list[book_id]++
      this.$cookies.set('cart', list)
      this.refreshQuantity++;

      // Alert LITERATURA
      if (book_id == 29) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CASTELLANA"
      } else if (book_id == 30) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CATALANA"
      }
    },
    mdiPlus() {
      return mdiPlus
    },
    mdiMinusThick() {
      return mdiMinusThick
    },
    mdiMinus() {
      return mdiMinus
    },
    mdiChevronLeft() {
      return mdiChevronLeft
    },
    mdiChevronRight() {
      return mdiChevronRight
    },
    async addToCart(book = null) {
      let book_id = this.$route.params.book_id;
      if (book != null) {
        book_id = book.book_id
      }
      this.$root.cart++;
      let list = this.$cookies.get('cart');
      if (!list[book_id]) list[book_id] = 0;
      list[book_id]++

      // Alert LITERATURA
      if (book_id == 29) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CASTELLANA"
      } else if (book_id == 30) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CATALANA"
      }


      this.$cookies.set('cart', list)
      this.showAfterBuyDialog = true;
      this.suggestedBooks = await this.suggestBooks();

      setTimeout(() => {
        this.showAfterBuyDialog = false;
      }, 3000)
    },
    async fetchAllBooks() {
      let {data} = await this.axios.get('/shop-list')
      this.shopList = data;
      this.allBooks = []
      data.forEach(d => this.allBooks = this.allBooks.concat([...d.subjects, ...d.apunts]));

      let found = false, i = 0;
      while (!found && i < this.allBooks.length) {
        const s = this.allBooks[i];
        if (s.book_id == this.$route.params.book_id) {
          this.book = s;
          found = true;
        }

        i++;
      }

      if (found && this.book) {
        this.selectedSubjectName = this.book.new_subject_name || this.book.subject_name
        this.path[1].text = this.book.new_subject_name || this.book.subject_name
      }
    },
    async suggestBooks() {
      const book_id = parseInt(this.$route.params.book_id);
      const book = this.allBooks.find(b => b.book_id == book_id);

      // Examens
      const numberOfExamens = Math.floor(this.columns / 2);
      let examens = this.allBooks.filter(b => !b.apunts)
      examens.sort((a, b) => (a.sold_units > b.sold_units ? -1 : 1))
      examens.sort((a, b) => a.branch_id == book.branch_id ? -1 : (b.branch_id == book.branch_id ? 1 : -1))
      examens = examens.filter(b => !this.list[b.book_id] && b.book_id !== book_id).slice(0, numberOfExamens);


      // Apunts
      // const numberOfApunts = Math.ceil(this.columns / 2);
      // let apunts = this.allBooks.filter(b => b.apunts)
      // apunts.sort((a, b) => (a.sold_units > b.sold_units ? -1 : 1))
      // apunts.sort((a, b) => a.branch_id == book.branch_id ? -1 : (b.branch_id == book.branch_id ? 1 : -1))
      // apunts = apunts.filter(b => !this.list[b.book_id] && b.book_id !== book_id).slice(0, numberOfApunts);

      // Combine exams and apunts
      // let result = this.shuffle([...apunts, ...examens]);
      let result = this.shuffle(examens);

      const sameSubjectItem = result.find(a => a.subject_id == this.book.subject_id)
      if (sameSubjectItem) {
        result = result.filter(item => item !== sameSubjectItem);
        result.unshift(sameSubjectItem);
      }

      return result
    },
    shuffle(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    spiralImage() {
      return this.url + '/images/other/spiral.png'
    },
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
    async onSelectSubject(book) {
      this.selectedSubject = book.subject_id;
      this.selectedSubjectName = book.subject_name;
      this.showPdf = true;
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      async handler(val) {

        try {
          this.loading = true;
          // this.showAfterBuyDialog = false;
          this.step = 0;
          await this.fetchAllBooks()
          this.suggestedBooks = await this.suggestBooks()
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    }
  }
}
</script>

<style scoped>

.pdf-viewer >>> .grey-scale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/*.pdf-viewer >>> img:nth-child(2) {*/
/*  -webkit-animation: flip-in-ver-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;*/
/*  animation: flip-in-ver-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;*/

/*}*/

/*.pdf-viewer >>> img:nth-child(1) {*/
/*  -webkit-animation: flip-in-ver-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;*/
/*  animation: flip-in-ver-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;*/
/*}*/


/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}


.book-rating >>> button {
  padding: 0 !important;
}


/**
 * ----------------------------------------
 * animation flip-in-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}


</style>
