<template>
  <v-dialog
    v-model="drawer"
    style="overflow: hidden !important;"
    :scrollable="false"
    max-width="1350"
  >
    <v-card height="100%" style="position: relative !important; overflow: hidden"
            class="d-flex flex-column"
    >

      <v-card class="justify-center my-10 text-center mx-auto pa-3" max-width="500" color="grey lighten-4" flat>
        <v-card color="transparent" class="rounded-circle mx-auto" height="26" width="26" flat>
          <v-icon color="primary">{{ mdiCheck() }}</v-icon>
        </v-card>
        <span v-if="selectedSubjectName" class="mt-2 font-rubik font-weight-medium">
          El dossier {{ deApostrof(selectedSubjectName) }} ha sigut afegit a la cistella
        </span>

      </v-card>

      <v-card
        v-if="$vuetify.breakpoint.xs"
        color="grey lighten-4" flat class="mb-12 mt-3 pa-10">
        <v-hover
          v-slot:default="{hover}">
          <v-btn
            @click="$router.push({path: '/dossiers-selectivitat/cart'})"
            height="38" style="max-height: 38px !important;"
            max-width="500" width="100%"
            :class="hover ? 'shadow-small' : 'shadow-sm'"
            depressed color="primary" class="mx-auto text-none font-weight-medium body-2 rounded-lg">
            Anar a la cistella
          </v-btn>
        </v-hover>
      </v-card>

      <items-row
        v-if="suggestedBooks && suggestedBooks.length"
        title="Normalment es compren junts"
        :shop="true"
        :items="suggestedBooks.slice(0, $vuetify.breakpoint.smAndDown ? 6 : 8)"
        class="mx-10 my-0 py-0"
      ></items-row>

      <v-card
        v-if="!$vuetify.breakpoint.xs"
        color="grey lighten-4" flat class="d-flex py-4"
        tile
      >
        <v-hover
          v-slot:default="{hover}">
          <v-btn
            v-if="i"
            @click="drawer=false, i.checkoutDrawer=true"
            height="42" style="max-height: 42px !important;"
            max-width="500" width="100%"
            :class="hover ? 'shadow-small' : 'shadow-sm'"
            depressed color="primary" class="mx-auto text-none font-weight-medium font-rubik rounded-lg">
            Anar a la cistella
          </v-btn>
        </v-hover>
      </v-card>

      <v-icon @click="drawer=false" style="position: absolute; right: 15px; top: 15px">{{
          mdiClose()
        }}
      </v-icon>
    </v-card>

  </v-dialog>

</template>

<script>
import {mdiCheck, mdiClose} from "/src/assets/mdi.json";
import ItemsRow from "./ProductsRow.vue";
import Shop from "../Shop";

export default {
  name: "DialogAfterBuy",
  components: {ItemsRow},
  mounted() {
    this.i = Shop.Instance
  },
  data() {
    return {
      i: null,
      drawer: false
    }
  },
  props: {
    value: Boolean,
    selectedSubjectName: String,
    suggestedBooks: Array,
  },
  methods: {
    mdiCheck() {
      return mdiCheck
    },
    mdiClose() {
      return mdiClose
    },
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    }
  },
  watch: {
    drawer(val) {
      this.$emit('update:value', val)
    },
    value(val) {
      if (val)
        this.drawer = val;
    }
  }
}
</script>

<style scoped>

</style>
